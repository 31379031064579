.dashboard {
  background-color: white;
  padding: 60px;
  min-height: calc(100vh - 67.44px);
  display: flex;

  &-header {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  &-actions {
    justify-self: center;
    &-btn {
      display: flex;
      justify-content: center;

      &-title {
        font-weight: $font-weight-bold;
        color: $text-color-primary !important;
        font-size: 16px !important;
      }
      button {
        margin: 10px;
        width: 100%;
        border-radius: 8px;
        border: 2px solid $light-gray-background;
        background-color: transparent;
        padding: 10px 30px;

        p {
          color: $text-color-primary-hover;
          font-size: 14px;
        }

        img,
        i {
          height: 40px;
          margin-bottom: 14px;
          font-size: 40px;
        }
      }
    }
  }

  &-info {
    margin-top: 40px;
    text-align: center;

    h1 {
      margin-bottom: 30px;
    }
  }
}
