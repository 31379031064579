.account {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }

  &-content {
    margin: 50px 0 0 10px;
  }
}
