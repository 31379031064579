.main {
  background-color: $main-background;
  min-height: 100vh;
  min-width: 100%;

  &-header {
    background-color: white;
    display: flex;
    box-shadow: $header-shadow;
    position: relative;
  }

  &-logo {
    padding: 15px;
  }

  &-content {
    @media (max-width: 768px) {
      padding: 0em;
      margin-top: 0em;
    }

    &-menu {
      @media (min-width: 992px) {
        max-width: 200px;
      }

      @media (max-width: 992px) {
        margin-bottom: -72px;
      }
    }
  }
}

.sign-out {
  margin-top: 0;
  margin-bottom: 0;

  &-item {
    height: 100%;
    margin-bottom: 0 !important;
    align-items: center;
    @media (min-width: 500px) {
      width: 200px;
    }

    &-link {
      text-decoration: none;
      color: $text-color-secondary;

      &:hover {
        color: $text-color-secondary-hover-dark;
        cursor: pointer;
      }
    }
  }
}
