.user-wrapper {
  display: flex;
  flex-direction: column;

  p:first-child {
    margin-bottom: 0;
  }
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 0;
}

.navbar-dropdown {
  .dropdown-menu {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    display: block;
    background-color: transparent;
    border: none;
    padding: 0 0 0 1em;
  }

  &.show .dropdown-menu {
    transform: scaleY(1);
  }

  .dropdown-toggle.btn-primary {
    background-color: transparent;
    border: none;
    filter: none;
    color: $text-color-primary;
    padding: 0.5rem 0 0.5rem 0;
    transition: none;

    &:hover {
      color: $color-black;
    }
  }

  .dropdown-item {
    color: $text-color-primary;

    &:hover {
      color: $color-black;
    }

    &.active,
    &:active {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.9);
    }

    &:focus,
    &:hover {
      background-color: transparent;
    }
  }
}

.navbar-nav .nav-link:hover {
  color: $color-black;
}

a.d-flex.menu-gap.nav-link:hover {
  color: $color-black;
}

.menu-gap {
  gap: 4px;
}

@media (max-width: 768px) {
  .navbar {
    justify-content: flex-end;
    padding-right: 12px;
  }
}

.navbar-nav .nav-link,
.mobile-menu .nav-link {
  color: $text-color-primary !important;
}

.mobile-menu .dropdown-menu {
  border: none;
  padding: 0.25rem 0;
  margin-left: 1em;
}

.custom-sidebar {
  @media (min-width: 1000px) {
    flex: 0 0 10%;
    max-width: 10%;
  }

  @media (min-width: 990px) {
    flex: 0 0 11%;
    max-width: 11%;
  }
}
