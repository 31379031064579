$text-color-primary: #3a4e48;
$text-color-primary-hover: #847f7f;
$text-color-secondary: #ec6c5d;
$text-color-secondary-hover: #f7897d;
$text-color-secondary-hover-dark: #b0594f;

$color-white: #fff;
$color-black: #000;

$status-success: #e1fbe0;
$status-success-text: #5ea64c;
$status-failed: #fbe0e0;
$status-failed-text: #a64c4c;
$status-warning: #fbf5e0;
$status-warning-text: #ddac0d;

$font-weight-light: 200;
$font-weight-bold: 600;

$main-background: #f3f2ef;
$light-gray-background: #f3f3f3;
$gray-background: #bdbdbd;

$gray-border: #ddd;

$transition-duration: 0.3s;

$box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
$header-shadow: 0 1.6px 3.6px rgba(0, 0, 0, 0.132), 0 0.3px 0.9px rgba(0, 0, 0, 0.108);

$filter-color-primary: brightness(0) saturate(100%) invert(25%) sepia(8%) saturate(1263%) hue-rotate(111deg) brightness(95%) contrast(82%);
$filter-color-secondary: brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(329%) hue-rotate(208deg) brightness(115%) contrast(100%);
$filter-color-white: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(21deg) brightness(104%) contrast(101%);
$filter-color-light-gray: brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(184%) hue-rotate(335deg) brightness(111%) contrast(90%);