.csv-import {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }

  &-form {
    margin-top: 14px;
    max-width: 380px;
    &-group {
      @media (min-width: 500px) {
        display: flex;
        justify-content: space-between;
        .admin-form-input {
          width: 185px;
        }
      }
    }

    &-input {
      width: 380px;
      margin-bottom: 15px;

      @media (max-width: 520px) {
        width: auto;
      }
      label {
        font-size: 15px;
        margin-bottom: 0;
      }
      input {
        border-radius: 8px;
        border: 1px solid $gray-background;
        height: 30px;
        font-size: 15px;
        &:disabled {
          background-color: $main-background !important;
        }
      }
    }

    &-btn {
      height: 30px;
      font-size: 16px;
      padding: 0 10px 0 10px;
      border-radius: 8px;
      margin-top: 8px;
    }
  }

  &-status {
    border-radius: 8px;
    padding: 6px 15px 6px 15px;
    &.success {
      background-color: $status-success;
      border: 1px solid $status-success-text;
      color: $status-success-text;
    }
    &.failed {
      background-color: $status-failed;
      border: 1px solid $status-failed-text;
      color: $status-failed-text;
    }
  }

  &-vendor {
    &-card {
      padding: 8px;
      margin: 12px 0;
      max-width: 250px;
      min-width: 200px;

      .card-img-top {
        height: 150px;
        object-fit: contain;
      }

      &-btn {
        display: flex;
        justify-content: space-between;
        button {
          background-color: transparent;
          border: none;
          padding: 0;
          position: relative;
          img,
          i {
            font-size: 25px;
            height: 25px;
            filter: brightness(0) saturate(100%) invert(47%) sepia(14%) saturate(3440%) hue-rotate(321deg) brightness(116%) contrast(85%);
          }
        }

        &:hover {
          span {
            visibility: visible;
          }
        }
        span {
          visibility: hidden;
          background-color: white;
          color: black;
          border-radius: 6px;
          position: absolute;
          z-index: 1;
          font-size: 14px;
          padding: 0 8px;
          right: 25px;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }
  }
}
