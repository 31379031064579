.pagination {
  margin-bottom: 4em;

  .page-link {
    color: $text-color-secondary;

    &:hover {
      z-index: 0;
    }
  }

  .page-item.active .page-link {
    background-color: $text-color-secondary;
    border-color: $text-color-secondary;
    color: $color-white;
    z-index: 0;
  }
}
