.import {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);
  margin-left: -12px;
  margin-right: -12px;

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }
}

.heading-intro {
  margin-top: 50px;
  margin-bottom: 0;
  padding: 0 0 0 4px;
  h1 {
    font-size: 24px;
  }
  h5 {
    font-size: 16px;
  }
}

/* Vendor */
.vendor {
  &-card {
    margin: 20px;
    padding: 15px;
  }

  &-select__control {
    border-radius: 20px !important;
  }

  .card-img-top {
    height: 200px;
    object-fit: contain;
  }
}

/* Edit product */
.edit-options {
  padding: 12px;
  &-category {
    border-radius: 20px;
  }
}

.edit-custom-checkbox {
  display: flex;
  font-size: 15px;
  input[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  &-check {
    display: inline-block;
    height: 18px;
    width: 18px;
    background: $color-white;
    border: 1px $gray-background solid;
    border-radius: 3px;
    margin-right: 8px;
    cursor: pointer;

    img,
    i {
      vertical-align: top;
      filter: $filter-color-primary;
      height: inherit !important;
      width: inherit !important;
      padding: 3px;

      &.hidden {
        filter: $filter-color-white;
      }
    }

    i {
      font-size: 12px;
    }
  }

  &-text {
    margin-top: -2px;
    span {
      color: $gray-background;
    }
  }

  &.big-checkbox {
    .edit-custom-checkbox-check {
      height: 22px;
      width: 22px;

      i {
        font-size: 16px;
      }
    }
  }

  &.variable-checkbox {
    .edit-custom-checkbox-check {
      height: 16px;
      width: 16px;
      background: $main-background;
      background: $main-background;
      img.hidden,
      i.hidden {
        filter: $filter-color-light-gray;
      }
    }
  }

  label:hover .edit-custom-checkbox-check {
    border: 1px #9a9a9a solid;
  }
}

.mass-edit {
  margin: 20px 10px 0 10px;
  border: 1px solid $gray-border;
  padding: 15px;

  &-header {
    display: flex;
    justify-content: space-between;
    p {
      font-weight: $font-weight-bold;
      color: $text-color-primary;
    }
  }

  &-subheader {
    margin-bottom: 5px;
    color: $text-color-primary;
    display: flex;

    &-tooltip {
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      padding-left: 3px;
      img {
        height: 15px;
        filter: $filter-color-primary;
      }
      &:hover {
        cursor: help;
        span {
          visibility: visible;
        }
      }
      span {
        visibility: hidden;
        background-color: $text-color-primary;
        color: $color-white;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        max-width: 250px;
        margin-left: 15px;
        font-size: 14px;
        padding: 4px;
      }
    }
  }

  &-close {
    height: 28px;
    width: 28px;
    padding: 0;
    img {
      height: 18px;
      filter: $filter-color-secondary;
      margin-bottom: 2px;
    }
  }

  &-search {
    min-width: 250px;
    div {
      input {
        font-size: 14px;
        margin-bottom: -1px;
        padding-top: 4px;
        padding: 2px 0 2px 40px;
        border-radius: 0 !important;
      }
    }
  }

  &-checkboxes {
    border: 1px solid $gray-border;
    padding: 10px;
    height: 150px;
    overflow: auto;
    color: $text-color-primary;
    min-width: 250px;

    &-no-selected {
      font-size: 13px;
      color: $text-color-secondary;
    }
  }

  &-options {
    font-size: 15px;
    color: $text-color-primary;
    min-width: 300px;
    input,
    select {
      font-size: 15px;
      padding: 0 5px 0 5px;
      border-radius: 0;
      margin-bottom: 5px;
      color: $text-color-primary;
    }

    &-btn {
      padding: 2px 12px 2px 12px !important;
      font-size: 15px;
      height: 30px;
    }

    &-remove {
      display: flex;
      padding: 0;
      width: 30px;
      &-btn {
        background-color: $text-color-secondary !important;
        border: none;
        height: 25px;
        width: 25px;
        padding: 0 !important;
        justify-content: right;
        img {
          margin-bottom: 1px;
          height: 17px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(329%) hue-rotate(208deg) brightness(115%) contrast(100%);
        }

        &:hover,
        &:active {
          background-color: $text-color-secondary !important;
          filter: drop-shadow($box-shadow);
        }
      }
    }
  }

  &-btn {
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
  }
}

/* Product table */
.multi-line-wrapper {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  align-items: baseline !important;
}

.product-table {
  th {
    position: sticky;
    top: 0;
    box-shadow: inset 0px -1px $light-gray-background;
    z-index: 1;
    padding: 10px 3px 10px 3px;
  }

  & tbody {
    background-color: $color-white !important;
    font-size: 14px;

    td {
      align-content: center;
      padding: 10px 5px 10px 5px;

      label,
      small {
        padding: 0;
      }
    }
  }

  & thead th {
    background-color: $main-background;
    background-color: $main-background;
    border: none;
    font-size: 16px;
    font-weight: $font-weight-light;
    &:first-child {
      border-radius: 8px 0 0 0;
      width: 2%;
    }
    &:last-child {
      border-radius: 0 8px 0 0;
    }

    div {
      &:has(div):hover {
        cursor: pointer;
      }
    }
  }

  &-selected {
    --bs-table-bg: #f3f2ef !important;
  }

  &-variable {
    --bs-table-bg: #faf9f5;
  }
}

.product-table-variable.product-table-selected {
  --bs-table-bg: #eae9e6 !important;
}

.product-item {
  align-content: center;
  justify-content: center;
  padding: 10px;
  font-size: 0.875em;

  &-container {
    display: flex;
    align-items: center;
  }

  &-expand {
    span {
      font-size: 12px;
      border: none;
      margin-right: 8px;
      cursor: pointer;
      img,
      i {
        height: 18px !important;
        margin-bottom: 5px;
        filter: brightness(0) saturate(100%) invert(25%) sepia(8%) saturate(1263%) hue-rotate(111deg) brightness(95%) contrast(82%);
      }
    }

    &:hover {
      span {
        img,
        i {
          filter: brightness(0) saturate(100%) invert(44%) sepia(8%) saturate(1081%) hue-rotate(111deg) brightness(92%) contrast(86%);
        }
      }
    }
  }

  &-checkbox {
    justify-items: left;
    padding-left: 10px !important;
    cursor: pointer;
  }
  &-editable,
  &-editable-label {
    &:hover {
      background-color: #edece8;

      .add-sales {
        visibility: visible;
      }

      &.product-item-editable-label,
      &.product-item-editable-label {
        &:hover {
          cursor: pointer;
        }
      }

      .editable-icon {
        visibility: visible;
      }
    }

    .editable-icon {
      visibility: hidden;
      height: 13px;
      margin-left: 5px;
      filter: $filter-color-primary;
      &-focus {
        visibility: visible;
      }
    }

    input:focus {
      outline: none;
      padding: 4px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    &-label,
    small {
      border: 1px solid transparent;

      &.add-sales {
        visibility: hidden;
        font-size: 8px;
        margin-bottom: -14px;
      }
    }
  }

  label,
  small {
    padding: 4px;
  }

  img,
  i {
    height: 50px;
    max-width: 100px;
    object-fit: contain;
  }

  &-edit {
    &-btn {
      cursor: pointer;
      img,
      i {
        height: 18px;
        max-width: 50px;
        object-fit: contain;
        filter: $filter-color-primary;
      }
      &:hover {
        img,
        i {
          filter: brightness(0) saturate(100%) invert(44%) sepia(8%) saturate(1081%) hue-rotate(111deg) brightness(92%) contrast(86%);
        }
      }
    }
  }

  &-remove-btn {
    margin-right: 8px;
    img {
      filter: brightness(0) saturate(100%) invert(47%) sepia(14%) saturate(3440%) hue-rotate(321deg) brightness(116%) contrast(85%);
    }

    &:hover {
      img {
        filter: brightness(0) saturate(100%) invert(64%) sepia(32%) saturate(3332%) hue-rotate(319deg) brightness(100%) contrast(98%);
      }
    }
  }

  &-whitespace {
    width: 20px;
  }
}

.product-variable {
  img {
    height: 30px;
  }

  input[type='checkbox'] {
    width: 14px;
    height: 14px;
  }
}

.sorting-label {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  img,
  i {
    margin-left: 5px;
    height: 18px;
    filter: $filter-color-primary;
  }
}

/* Proces bar  */
.steps {
  padding-left: 36px;
  padding-right: 36px;
  .step {
    border: 2px solid #bdbdbd;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    align-content: center;
    font-size: 20px;
    color: $text-color-primary;
    &.active {
      background-color: $text-color-primary;
      border: 2px solid $text-color-primary;

      color: white;
    }
    &-wrapper {
      justify-content: center;
      position: relative;
      display: flex;
      p {
        position: absolute;
        margin-top: 40px;
      }
    }
  }

  .arrow {
    display: block;
    border-color: $gray-background;
    border-top-style: solid;
    border-top-width: 2px;

    &.active {
      border-color: $text-color-primary;
      border-top-width: 2px;
    }

    &-wrapper {
      flex: 1 1 auto;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

/* Modal */
.edit-product-modal {
  .modal-dialog {
    max-width: 90vw;

    .modal-content {
      max-height: 95vh;
    }
  }

  &-header {
    color: $text-color-primary;

    h1 {
      font-size: 26px;
    }

    h2 {
      font-size: 18px;
    }
  }

  &-close {
    padding: 4px 8px;
    img {
      height: 26px;
      filter: $filter-color-secondary;
    }
  }

  &-remove {
    display: flex;
    padding: 0;
    width: 30px;
    button {
      height: 28px;
      width: 28px;
      padding: 0;
      margin-right: -14px;

      img {
        height: 18px;
        filter: $filter-color-secondary;
        margin-bottom: 2px;
      }
    }
  }

  .modal-body {
    max-height: 80vh;
    overflow: auto;

    @media (max-height: 1100px) {
      max-height: 75vh;
    }

    @media (max-height: 830px) {
      max-height: 70vh;
    }
  }

  &-body {
    display: inline;
    @media (min-width: 900px) {
      display: flex;
    }

    &-image {
      max-width: 280px;
      @media (min-width: 900px) {
        max-width: 400px;
      }
    }
    &-input {
      margin-bottom: 10px;
      padding: 0;
      label {
        font-size: 14px;
        margin-bottom: 0;
      }

      input,
      select,
      textarea {
        padding: 2px 12px 2px 12px;
        border-radius: 8px;
        font-size: 15px;
        height: 30px;
      }

      &-btn {
        padding: 2px 12px 2px 12px !important;
        border-radius: 8px;
        font-size: 15px;
        height: 30px;
        background-color: $text-color-primary !important;
      }

      textarea {
        height: 80px !important;
      }

      .modal-select {
        font-size: 15px;
        &__control {
          border-radius: 8px;
          height: 30px;
          align-content: center;
        }
      }

      .stock-input {
        input {
          margin-left: -22px;
          padding-left: 26px;
        }
        span {
          z-index: 100;
          align-self: center;
          margin: 0 0 0 10px;
        }
      }
    }
  }

  .modal-footer {
    justify-content: space-between;
    min-height: 70px;
  }

  &-footer {
    p {
      margin-bottom: 0;
      font-size: 12px;
    }
  }

  &-buttons {
    display: flex;
    justify-content: right;
    button {
      margin: 0 5px 0 5px;
      border-radius: 8px;
    }
  }

  &-nav-btn {
    max-width: 50px;
    align-content: center;

    &.col {
      height: 70vh;
    }

    button {
      background-color: transparent;
      border: none;
      position: fixed;
      z-index: 10;
      padding: 0;

      &.hidden {
        display: none;
      }

      img {
        height: 40px;
        filter: $filter-color-primary;
      }
    }
  }

  &-variation {
    margin: 35px;
    &-image {
      height: 50px;
      margin-right: 10px;
    }

    button {
      padding: 5px 20px 5px 5px;
      &:not(.collapsed) {
        background-color: $light-gray-background;
      }
    }
  }
}

.in-stock {
  background-color: $status-success-text;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.out-of-stock {
  background-color: $text-color-secondary;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.stock-split {
  background: linear-gradient(to right, $status-success-text 50%, $text-color-secondary 50% 100%);
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.whitespace {
  width: 20px;
}

.show-more {
  position: relative;
  z-index: 10;
}

/* Confirm import */
.confirm-import-container {
  min-height: 25vh;

  .progress-bar {
    background-color: $text-color-primary;
    --bs-bg-opacity: 1;
  }
}

.import-finished .checkmark {
  width: 30%;
  margin: auto;
}

/* Footer navigation */
.footer-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .card-body {
    padding: 0.25rem 0.25rem;
  }

  .btn.next,
  .btn.previous {
    border-radius: 20px;
    display: flex;
    align-items: center;

    img {
      height: 18px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(3%) hue-rotate(298deg) brightness(105%) contrast(100%);
    }
  }

  .btn.next {
    float: right;
  }

  @media (max-width: 767px) {
    .btn.next,
    .btn.previous {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      border-radius: 0.2rem;
    }
  }

  @media (min-width: 1000px) {
    position: fixed;
    bottom: 0;
    left: 2rem;
    right: 0;
  }

  @media (min-width: 990px) {
    position: fixed;
    bottom: 0;
    left: 3rem;
    right: 0;

    .card-body {
      padding: 0.5rem 0.5rem;
    }
  }
}

/* Toast */
.toast-container {
  animation: moveRight 0.5s forwards;
  position: fixed;
  top: 90px;
  right: 25px;
}

@keyframes moveRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
