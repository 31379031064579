/* Buttons */
.btn-primary {
  background-color: $text-color-primary;
  border-color: $text-color-primary;
  border-radius: 20px;

  &:hover {
    background-color: $text-color-primary;
    border-color: $text-color-primary;
    filter: drop-shadow($box-shadow);
  }

  &.disabled,
  &:disabled {
    background-color: $text-color-primary;
    border-color: $text-color-primary;
    opacity: 0.5;
  }

  &:focus {
    background-color: $text-color-primary;
    border-color: $text-color-primary;
    filter: drop-shadow($box-shadow);
  }

  &.active,
  &:active {
    background-color: $text-color-primary;
    border-color: $text-color-primary;
    filter: drop-shadow($box-shadow);

    &:focus,
    &:focus {
      box-shadow: none;
    }
  }
}

.btn-secondary {
  background-color: $text-color-secondary;
  color: $color-white;
  border-color: $text-color-secondary;
  
  &:hover {
    background-color: $text-color-secondary;
    border-color: $text-color-secondary;
    filter: drop-shadow($box-shadow);
    color: $main-background;
  }
  
  &:active {
    background-color: $text-color-secondary !important;
    border-color: $text-color-secondary !important;
    filter: drop-shadow($box-shadow) !important;
    color: $main-background !important;
  }
}

.show > .btn-primary.dropdown-toggle {
  background-color: $text-color-primary;
  border-color: $text-color-primary;
  filter: drop-shadow($box-shadow);
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: $text-color-primary;
  border-color: $text-color-primary;
  filter: drop-shadow($box-shadow);
  scale: 0.98;
}

/*  Custom buttons */

.button-orange {
  background-color: $text-color-secondary !important;
  border-color: $text-color-secondary !important;
  
  &:hover {
    background-color: $text-color-secondary;
    border-color: $text-color-secondary;
    filter: drop-shadow($box-shadow);
  }
  
  &.btn-primary.disabled,
  &.btn-primary:disabled {
    background-color: $text-color-secondary;
    border-color: $text-color-secondary;
    opacity: 0.5;
  }
}

.deselect {
  background-color: $text-color-secondary !important;
  border-color: $text-color-secondary !important;
}
