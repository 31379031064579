.search {
  margin-left: -12px;
  &-input {
    border-radius: 20px !important;
    border-color: $gray-border;
    padding-left: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: -30px;
    &::-webkit-search-cancel-button {
      display: none;
    }

    &:focus,
    &:hover {
      border-color: $gray-background;
      box-shadow: none;
    }
  }

  &-icon {
    padding-left: 26px;
    padding-bottom: 3px;
    z-index: 1000;
    align-content: center;
    img,
    i {
      height: 16px;
      filter: $filter-color-primary;
    }
  }
}
