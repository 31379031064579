.integration {
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 67.44px);

  &-header {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 0 0 0 4px;
  }

  &-form {
    margin-top: 30px;
    max-width: 380px;
    &-group {
      @media (min-width: 500px) {
        display: flex;
        justify-content: space-between;
        .admin-form-input {
          width: 185px;
        }
      }
    }

    &-input {
      width: 380px;
      margin-bottom: 15px;

      @media (max-width: 520px) {
        width: auto;
      }
      label {
        font-size: 15px;
        margin-bottom: 0;
      }
      input:not([type='checkbox']) {
        border-radius: 8px;
        border: 1px solid $gray-background;
        height: 30px;
        font-size: 15px;
        &:disabled {
          background-color: $main-background !important;
        }
      }
    }

    &-btn {
      height: 30px;
      font-size: 16px;
      padding: 0 10px 0 10px;
      border-radius: 8px;
      margin-top: 8px;
    }
  }

  &-status {
    border-radius: 8px;
    padding: 6px 15px 6px 15px;
    &.success {
      background-color: $status-success;
      border: 1px solid $status-success-text;
      color: $status-success-text;
    }
    &.failed {
      background-color: $status-failed;
      border: 1px solid $status-failed-text;
      color: $status-failed-text;
    }
  }

  &-categories {
    display: flex;
    flex-wrap: wrap;

    .card {
      padding: 10px;
      margin: 10px;
    }
  }
}
